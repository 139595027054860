import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import 'antd/dist/reset.css';
import { connect } from 'react-redux';
import { getPictureWeek, uploadImage, uploadName } from '../redux/pictureweek';
import { Upload, message, Input, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
const { Search } = Input;

const PictureWeek = ({
	pictureweek,
	getPictureWeek,
	uploadImage,
	uploadName,
}) => {
	const [error, setError] = useState(false);
	const [name, setName] = useState('');
	const [pictureOfTheWeekFiles, setPictureOfTheWeekFiles] = useState([]);
	const [loadingImage, setLoadingImage] = useState(false);

	useEffect(() => {
		getPictureWeek();
	}, []);

	useEffect(() => {
		setName(pictureweek.title);
		setPictureOfTheWeekFiles([
			{
				uid: '-1',
				name: pictureweek.title,
				status: 'done',
				url: pictureweek.url,
			},
		]);
	}, [pictureweek]);

	const handleChange = (value) => {
		setName(value.target.value);
	};

	const handleUpload = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		setLoadingImage(true);
		await uploadImage(formData);
		setLoadingImage(false);
	};

	const props = {
		name: 'banner',
		multiple: false,
		listType: 'picture',
		accept: '.png,.svg,.img,.jpg,.jpeg',
		maxCount: 1,
		showRemoveIcon: false,
		showUploadList: {
			showRemoveIcon: false,
		},
		fileList: pictureOfTheWeekFiles,
		beforeUpload: async (file) => {
			handleUpload(file);
			setPictureOfTheWeekFiles([file]);
			return false;
		},
	};

	const submitName = async (value) => {
		if (value.match('^[a-zA-Z0-9 ,.]{1,15}$')) {
			const payload = {
				title: value,
			};
			await uploadName(payload);
			setError(false);
			message.success(`Banner name updated successfully.`);
		} else {
			setError(true);
			message.error(`Banner name is not valid.`);
		}
	};

	return (
		<div
			style={{
				width: '100%',
				backgroundColor: '#fff',
				borderRadius: 10,
				padding: 16,
			}}>
			<h1>Picture of the Week settings</h1>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
				<h1
					style={{
						paddingTop: 4,
						paddingRight: 6,
					}}>
					Banner Name:
				</h1>
				<Search
					style={{ width: 400 }}
					placeholder="input banner name"
					enterButton="Save"
					size="default"
					value={name}
					onSearch={(value) => submitName(value)}
					onChange={handleChange}
				/>
				<div
					style={{
						color: error ? '#DC2626' : '#888888',
						fontSize: '12px',
					}}>
					Name should contain only A-z characters, &quot;,&quot; or
					&quot;.&quot; and be no longer than 15 characters.
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginTop: 16,
				}}>
				<h1
					style={{
						paddingTop: 4,
						paddingRight: 6,
					}}>
					Banner Image:
				</h1>

				<Upload {...props}>
					<Button icon={<UploadOutlined />} loading={loadingImage}>
						Upload Image
					</Button>
				</Upload>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.pictureweek,
	};
};

const mapDispatchToProps = { getPictureWeek, uploadImage, uploadName };

export default connect(mapStateToProps, mapDispatchToProps)(PictureWeek);
