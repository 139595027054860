import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLocations, setSelected as setLocation } from '../redux/location'
import { Table, Drawer, Space, Button, List } from 'antd';
import EditLocation from '../components/EditLocation';
import CreateLocation from '../components/CreateLocation';
import { PlusCircleOutlined } from '@ant-design/icons';

const Locations = ({ selected, locations, locationIds, setLocation, getLocations }) => {
	const [isCreateOpen, setCreateOpen] = useState(false);
	const [isEditOpen, setEditOpen] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);

	useEffect(() => {
		getLocations();
	}, [getLocations]);

	useEffect(() => {
		if (Array.isArray(locationIds)) {
			const _locations = locationIds.map(locId => {
				return { key: locId, ...locations[locId] };
			});
			setTableData(_locations);
		}
		const _columns = [
			{
				title: 'ID',
				dataIndex: 'id',
			},
			{
				title: 'Name',
				dataIndex: 'name',
				render: (text, row) => {
					return (
						<>
							<p>{text}</p>
							<small>{row.address}</small>
						</>
					)
				}
			},
			{
				title: 'Vet Services',
				dataIndex: 'hasVet',
				render: (val) => {
					return (
						<p>{val === 1 ? 'Yes' : 'No'}</p>
					)
				}
			},
			{
				title: 'Has Dog Training',
				dataIndex: 'hasDogTraining',
				render: (val) => {
					return (
						<p>{val ? 'Yes' : 'No'}</p>
					)
				}
			},
			{
				title: 'Alternates',
				dataIndex: 'alternates',
				render: (val, row) => {
					return (
						<List
							size="small"
							dataSource={row.alternates}
							renderItem={(alt) => < List.Item > {locations[alt.locId].name}</ List.Item >} />
					)
				}
			},
			{
				title: 'Non-Compat',
				dataIndex: 'noncompats',
				render: (val, row) => {
					return (
						<List
							size="small"
							dataSource={row.noncompats}
							renderItem={(item) => <List.Item>{item.name} (ID: {item.nonCompatId})</List.Item>} />
					)
				}
			},
		];
		setTableColumns(_columns);

	}, [locations, locationIds]);

	const onDrawerClose = () => {
		setLocation(null);
		setEditOpen(false);
		setCreateOpen(false);
	};

	const onRowClick = (location) => {
		setLocation(location);
		setCreateOpen(false);
		setEditOpen(true);
	}
	return (
		<div>
			<Space style={{ float: 'right', marginBottom: 16 }}>
				<Button type="primary" onClick={() => setCreateOpen(true)} style={{ background: '#008E87' }}>
					<PlusCircleOutlined /> New Location
				</Button>
			</Space>
			{tableData && <Table
				dataSource={tableData}
				columns={tableColumns}
				pagination={false}
				onRow={(record) => {
					return {
						onClick: () => { onRowClick(record) },
					};
				}} />}
			{selected && <Drawer
				title={`Edit ${selected?.name} [${selected.id}]`}
				placement="right"
				size={'large'}
				width={900}
				onClose={onDrawerClose}
				open={isEditOpen}
				extra={
					<Space>
						<Button onClick={onDrawerClose}>Cancel</Button>
						<Button type="primary" form="edit-location" htmlType='submit'>
							Update
						</Button>
					</Space>
				}>
				{selected && <EditLocation onClose={onDrawerClose} />}
			</Drawer>}
			<Drawer
				title="New Location"
				placement="right"
				size={'large'}
				width={900}
				onClose={onDrawerClose}
				open={isCreateOpen}
				extra={
					<Space>
						<Button onClick={onDrawerClose}>Cancel</Button>
						<Button type="primary" form="create-location" htmlType='submit'>
							Create
						</Button>
					</Space>
				}>
				{isCreateOpen && <CreateLocation onClose={onDrawerClose} />}
			</Drawer>
		</div>

	);
};

const mapStateToProps = (state) => {
	return {
		...state.locations
	};
};

const mapDispatchToProps = { getLocations, setLocation };

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
