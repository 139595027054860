import Network from './Network';

class PictureWeekService {
	static async get() {
		let response = await Network.request({
			method: 'GET',
			url: '/admin/picture-of-the-week',
		});
		return response.data;
	}

	static async uploadImage(payload) {
		let response = await Network.request({
			method: 'POST',
			url: '/admin/picture-of-the-week',
			data: payload,
		});
		return response.data;
	}

	static async uploadName(payload) {
		let response = await Network.request({
			method: 'PUT',
			url: '/admin/picture-of-the-week',
			data: payload,
		});
		return response.data;
	}
}

export default PictureWeekService;
