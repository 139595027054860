import env from '../env';
// TODO: Change API_URL when the URL's are available
const configurationSets = {
	development: {
		API_URL: 'https://dev.api.app.petparadise.com',
		networkTimeout: 60000,
		description: 'Dev',
		cognito: {
			region: 'us-east-1',
			user_pool_id: 'us-east-1_AgBWArE4i',
			user_pool_client_id: '4juu70nh3qbk58tnrq831liv5j',
			domain:
				'https://petparadise-middleware-admin-dev.auth.us-east-1.amazoncognito.com',
			logout_uri: 'https://dev.admin.app.petparadise.com/logout',
		},
	},
	qa: {
		API_URL: 'https://qa.api.app.petparadise.com',
		networkTimeout: 60000,
		description: 'QA',
		cognito: {
			region: 'us-east-1',
			user_pool_id: 'us-east-1_yKOLjlDGo',
			user_pool_client_id: '591hbq29c18irduilq5db4vtl1',
			domain:
				'https://petparadise-middleware-admin-qa.auth.us-east-1.amazoncognito.com',
			logout_uri: 'https://qa.admin.app.petparadise.com/logout',
		},
	},
	production: {
		API_URL: 'https://api.app.petparadise.com',
		networkTimeout: 60000,
		description: 'Prod',
		cognito: {
			region: 'us-east-1',
			user_pool_id: 'us-east-1_oH46OySv3',
			user_pool_client_id: '6rhd8jnd9l6bmimc8jmpvpoau0',
			domain: 'https://petparadise-middleware-admin.auth.us-east-1.amazoncognito.com',
			logout_uri: 'https://admin.app.petparadise.com/logout',
		},
	},
	local: {
		API_URL: 'http://localhost:1616',
		networkTimeout: 60000,
		description: 'Local',
		cognito: {
			region: 'us-east-1',
			user_pool_id: 'us-east-1_AgBWArE4i',
			user_pool_client_id: '4juu70nh3qbk58tnrq831liv5j',
			domain:
				'https://petparadise-middleware-admin-dev.auth.us-east-1.amazoncognito.com',
			logout_uri: 'http://localhost:3000/logout',
		},
	},
};

const configuration = {
	...configurationSets[env],
	environment: env,
};

export default configuration;
