import Config from '../services/Config';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
	Auth: {
		region: Config.cognito.region,
		userPoolId: Config.cognito.user_pool_id,
		userPoolWebClientId: Config.cognito.user_pool_client_id,
	},
});

class AuthService {

	static async getUserAttr() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			return user.attributes;
		} catch (error) {
			throw error.response;
		}
	}

	static async getToken() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const token = user.signInUserSession.accessToken.jwtToken;
			return token;
		} catch (error) {
			throw error.response;
		}
	}

	static async signOut() {
		try {
			return await Auth.signOut();
		} catch (error) {
			throw error.response;
		}
	}
}

export default AuthService;
