import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PictureWeek from '../services/PictureWeek';

export const getPictureWeek = createAsyncThunk(
	'pictureweek/get',
	async (payload, { dispatch, rejectWithValue }) => {
		try {
			const pictureweeks = await PictureWeek.get();
			dispatch({
				type: 'pictureweek/setPictureweek',
				payload: pictureweeks,
			});
			return pictureweeks;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const uploadImage = createAsyncThunk(
	'pictureweek/uploadImage',
	async (payload, { dispatch, rejectWithValue }) => {
		try {
			const pictureweek = await PictureWeek.uploadImage(payload);
			dispatch(getPictureWeek());
			return pictureweek;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const uploadName = createAsyncThunk(
	'pictureweek/uploadName',
	async (payload, { dispatch, rejectWithValue }) => {
		try {
			const pictureweek = await PictureWeek.uploadName(payload);
			dispatch(getPictureWeek());
			return pictureweek;
		} catch (error) {
			rejectWithValue(error);
		}
	},
);

export const pictureweekSlice = createSlice({
	name: 'pictureweek',
	initialState: {
		pictureweek: {
			url: null,
			title: null,
		},
		loading: false,
		selected: null,
		error: null,
	},
	reducers: {
		setPictureweek(state, action) {
			state.pictureweek = action.payload;
		},
	},
});

export const { setPictureweek } = pictureweekSlice.actions;

export default pictureweekSlice.reducer;
