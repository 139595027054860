import Network from './Network';

class NonCompatService {

	static async getAll() {
		let response = await Network.request({
			method: 'GET',
			url: '/admin/noncompats',
		});
		return response.data;
	}

	static async createNonCompat(payload) {
		let response = await Network.request({
			method: 'POST',
			url: '/admin/noncompats',
			data: payload
		});
		return response.data;
	}

	static async deleteNonCompat(id) {
		let response = await Network.request({
			method: 'DELETE',
			url: `/admin/noncompats/${id}`,
		});
		return response.data;
	}

}

export default NonCompatService;
