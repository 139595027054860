import React from 'react';
import { ReactComponent as LogoDark } from '../assets/PetLogo.svg';
import { Link } from 'react-router-dom';

const Logo = () => {
	return (
		<Link to="/">
			<LogoDark />
		</Link>
	);
};

export default Logo;
