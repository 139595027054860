import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NonCompatService from '../services/NonCompat';

export const getNonCompats = createAsyncThunk(
	'noncompat/getAll',
	async () => {
		return await NonCompatService.getAll();
	},
);

export const createNonCompat = createAsyncThunk(
	'noncompat/create',
	async (payload, { dispatch }) => {
		const nonCompat = await NonCompatService.createNonCompat(payload);
		dispatch(getNonCompats());
		return nonCompat;
	},
);

export const deleteNonCompat = createAsyncThunk(
	'noncompat/remove',
	async (id, { dispatch }) => {
		const status = await NonCompatService.deleteNonCompat(id);
		dispatch(getNonCompats());
		return status;
	},
);

export const nonCompatSlice = createSlice({
	name: 'noncompats',
	initialState: {
		noncompats: [],
		loading: false,
		selected: null,
		error: null
	},
	reducers: {
		setSelected(state, action) {
			state.selected = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase('noncompat/getAll/pending', (state) => {
				return { ...state, loading: true };
			})
			.addCase('noncompat/getAll/fulfilled', (state, action) => {
				return { ...state, noncompats: action.payload };
			})
			.addCase('noncompat/getAll/rejected', (state, action) => {
				return { ...state, loading: false, error: action.error.message };
			});

	}
});

export const { setSelected } = nonCompatSlice.actions;

export default nonCompatSlice.reducer;
