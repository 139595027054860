import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MinstayService from '../services/Minstays';
import { normalize, schema } from 'normalizr';

const minstay = new schema.Entity('minstays', undefined, {
	idAttribute: ({ id }) => id,
});

export const getMinstays = createAsyncThunk(
	'minstay/getAll',
	async () => {
		const minstays = await MinstayService.getAll();
		const normalized = normalize(minstays, [minstay]);
		return normalized;
	},
);

export const createMinstay = createAsyncThunk(
	'minstay/create',
	async (payload, { dispatch }) => {
		const minstay = await MinstayService.create(payload);
		dispatch(getMinstays());
		return minstay;
	},
);

export const updateMinstay = createAsyncThunk(
	'minstay/update',
	async (payload, { dispatch }) => {
		const minstay = await MinstayService.update(payload);
		dispatch(getMinstays());
		return minstay;
	},
);

export const deleteMinstay = createAsyncThunk(
	'minstay/remove',
	async (payload, { dispatch }) => {
		const minstay = await MinstayService.delete(payload);
		dispatch(getMinstays());
		return minstay;
	},
);

export const minstaySlice = createSlice({
	name: 'minstays',
	initialState: {
		minstays: [],
		minstayIds: [],
		loading: false,
		selected: null,
		error: null
	},
	reducers: {
		setSelected(state, action) {
			state.selected = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase('minstay/getAll/pending', (state) => {
				return { ...state, loading: true };
			})
			.addCase('minstay/getAll/fulfilled', (state, action) => {
				return {
					...state,
					minstays: action.payload.entities.minstays,
					minstayIds: action.payload.result,
				};
			})
			.addCase('minstay/getAll/rejected', (state, action) => {
				return { ...state, loading: false, error: action.error.message };
			});

	}
});

export const { setSelected } = minstaySlice.actions;

export default minstaySlice.reducer;
