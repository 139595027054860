import axios from 'axios';
import Config from '../services/Config';
import AuthService from '../services/Auth';

class Network {
	static async rawRequest(params) {
		try {
			const response = await axios(params);
			return response;
		} catch (error) {
			throw error.response;
		}
	}

	static async request(params, multimedia = false) {
		const token = await AuthService.getToken();
		let headers = {
			Authorization: `Bearer ${token}`,
		};

		if (multimedia) {
			headers = {
				...headers,
				'Content-Type': 'multipart/form-data',
			};
		}

		return this.rawRequest({
			...params,
			headers: headers,
			url: Config.API_URL + params.url,
		});
	}
}

export default Network;
