import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getMinstays, setSelected as setMinstay, deleteMinstay } from '../redux/minstay'
import { Table, Drawer, Space, Button, List, Popconfirm } from 'antd';
import {
	EditOutlined,
	DeleteOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons';
import CreateMinstay from 'src/components/CreateMinstay';
import EditMinstay from 'src/components/EditMinstay';

const Minstays = ({ selected, minstays, minstayIds, locationIds, getMinstays, setMinstay, deleteMinstay }) => {
	const [isCreateOpen, setCreateOpen] = useState(false);
	const [isEditOpen, setEditOpen] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);

	useEffect(() => {
		getMinstays();
	}, [getMinstays]);

	useEffect(() => {
		// format table data
		if (Array.isArray(minstayIds) && Array.isArray(locationIds)) {
			const tabledata = minstayIds.map(minId => {
				return { key: minstays[minId].id, ...minstays[minId] };
			});
			setTableData(tabledata);
		}
		// setup table columns
		const columns = [
			{
				title: 'Label',
				dataIndex: 'label',
			},
			{
				title: 'Start',
				dataIndex: 'start',
			},
			{
				title: 'End',
				dataIndex: 'end',
			},
			{
				title: 'Nights',
				dataIndex: 'nights',
				render: (val) => {
					return (
						<p>{val === 1 ? `${val} day` : `${val} days`}</p>
					)
				}
			},
			{
				title: 'Locations',
				dataIndex: 'locations',
				render: (locations) => {
					return (<Locations locations={locations} locationIds={locationIds} />)
				}
			},
			{
				title: 'Action',
				render: (text, row) => {
					return (
						<>
							<Button
								onClick={() => onEdit(row)}
								style={{ margin: '5px' }}>
								<EditOutlined />
							</Button >

							<Popconfirm
								title="Please Confirm"
								description="Are you sure to delete this?"
								onConfirm={() => onDelete(row.id)}
								okText="Yes"
								cancelText="No">
								<Button>
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</>

					)
				}
			}
		];
		setTableColumns(columns);

	}, [minstays, minstayIds, locationIds]);



	const onDrawerClose = () => {
		setMinstay(null);
		setCreateOpen(false);
		setEditOpen(false);
	};

	const onEdit = (minstay) => {
		setMinstay(minstay);
		setEditOpen(true);
	}

	const onDelete = async (minstayId) => {
		await deleteMinstay(minstayId);
	}

	return (
		<div>
			<Space style={{ float: 'right', marginBottom: 16 }}>
				<Button type="primary" onClick={() => setCreateOpen(true)} style={{ background: '#008E87' }}>
					<PlusCircleOutlined /> New Minimum Stay
				</Button>
			</Space>
			{tableData && <Table
				dataSource={tableData}
				columns={tableColumns}
				pagination={false} />}
			<Drawer
				title={'New Minimum Stay'}
				placement="right"
				size={'large'}
				width={500}
				onClose={onDrawerClose}
				open={isCreateOpen}
				extra={
					<Space>
						<Button onClick={onDrawerClose}>Cancel</Button>
						<Button type="primary" form="create-minstay" htmlType='submit'>
							Create
						</Button>
					</Space>
				}>
				<CreateMinstay onClose={onDrawerClose} />
			</Drawer>
			{selected && <Drawer
				title={'Edit Minimum Stay'}
				placement="right"
				size={'large'}
				width={500}
				onClose={onDrawerClose}
				open={isEditOpen}
				extra={
					<Space>
						<Button onClick={onDrawerClose}>Cancel</Button>
						<Button type="primary" form="edit-minstay" htmlType='submit'>
							Update
						</Button>
					</Space>
				}>
				<EditMinstay onClose={onDrawerClose} />
			</Drawer>}
		</div>

	);
};

const Locations = ({ locations, locationIds }) => {
	const isAll = locations.every((loc) => {
		return locationIds.includes(loc.locId);
	});
	return isAll ? <div>All Locations</div> : <List
		size="small"
		dataSource={locations}
		renderItem={(loc) => < List.Item > {loc.locName}</ List.Item >} />;
};

const mapStateToProps = (state) => {
	return {
		...state.minstays,
		locationIds: state.locations.locationIds
	};
};

const mapDispatchToProps = { getMinstays, setMinstay, deleteMinstay };

export default connect(mapStateToProps, mapDispatchToProps)(Minstays);
