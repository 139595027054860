import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form, Input, Select, Button, Card, Spin } from 'antd';
import { getNonCompats } from '../redux/noncompat';
import {
	updateLocation,
	getLocationReservationTypes,
	getReservationTypeServices,
} from '../redux/location';

const EditLocation = ({
	selected,
	locations,
	locationIds,
	noncompats,
	getNonCompats,
	updateLocation,
	onClose,
}) => {
	const [form] = Form.useForm();
	const [locationOptions, setLocationOptions] = useState(null);
	const [nonCompatOptions, setNonCompatOptions] = useState(null);
	const [location, setLocation] = useState(null);
	const [reservationTypes, setReservationTypes] = useState([]);
	const [selectedReservationTypes, setSelectedReservationTypes] = useState([]);
	const dispatch = useDispatch();

	const fetchReservationTypes = useCallback(async () => {
		const reservationTypes = await dispatch(
			getLocationReservationTypes({
				locationId: location.id,
			}),
		).unwrap();
		setReservationTypes(reservationTypes);
	}, [location]);

	// set up locations
	useEffect(() => {
		if (Array.isArray(locationIds)) {
			const _options = [];
			for (const locId of locationIds) {
				_options.push({
					label: locations[locId].name,
					value: parseInt(locations[locId].id, 10),
				});
			}
			setLocationOptions(_options);
		}
	}, [locations]);

	// setup non-compat types
	useEffect(() => {
		if (noncompats) {
			const _options = [];
			for (const nc of noncompats) {
				const _nonId = parseInt(nc.nonCompatId, 10);
				_options.push({
					label: `${_nonId} - ${nc.name}`,
					value: _nonId,
				});
			}
			setNonCompatOptions(_options);
		}
	}, [noncompats]);

	useEffect(() => {
		if (location) {
			fetchReservationTypes();
		}
	}, [location]);

	// set location from redux
	useEffect(() => {
		if (selected) {
			setLocation(selected);
		}
	}, [selected]);

	//
	useEffect(() => {
		getNonCompats();
	}, [getNonCompats]);

	const onLocationSubmit = async (loc) => {
		const request = {
			locId: location.id,
			hasVet: loc.hasVet,
			vetstoriaMobile: loc.vetstoriaMobile || '',
			vetstoriaWeb: loc.vetstoriaWeb || '',
			noncompats: loc.noncompats,
			alternates: loc.alternates,
			merchantId: loc.merchantId ? loc.merchantId : undefined,
			hasDogTraining: Boolean(loc.hasDogTraining),
			locationFeatureServices: loc.featureReservationTypeServices || [],
		};
		await updateLocation(request);
		onClose();
	};

	const validateVetstoriaUrls = (_, value) => {
		const hasVet = form.getFieldValue('hasVet');
		if (parseInt(hasVet) === 1 && !value) {
			return Promise.reject(
				'This field is required when Vet Services are enabled',
			);
		}
		return Promise.resolve();
	};

	const onFeatureServiceRemove = (index) => {
		setLocation({
			...location,
			locationFeatureServices: location?.locationFeatureServices?.filter(
				(_, l) => index != l,
			),
		});
	};

	return (
		<div>
			{location && (
				<Form
					form={form}
					id="edit-location"
					name="edit-location"
					onFinish={onLocationSubmit}
					initialValues={{
						name: location.name,
						address: location.address,
						hasVet: String(location.hasVet),
						vetstoriaMobile: location.vetstoriaMobile,
						vetstoriaWeb: location.vetstoriaWeb,
						alternates: location.alternates.map((alt) => alt.locId),
						noncompats: location.noncompats.map((nc) => nc.nonCompatId),
						merchantId: location.merchantId,
						hasDogTraining: location.hasDogTraining ? 'Yes' : 'No',
						featureReservationTypeServices:
							location.locationFeatureServices ?? [],
					}}
					style={{
						maxWidth: 880,
					}}
					scrollToFirstError>
					<Form.Item
						name="name"
						label="Name"
						tooltip="This information is pulled from Gingr. Please manage it in Gingr.">
						<Input disabled />
					</Form.Item>

					<Form.Item
						name="address"
						label="Address"
						tooltip="This information is pulled from Gingr. Please manage it in Gingr.">
						<Input disabled />
					</Form.Item>

					<Form.Item
						name="hasVet"
						label="Vet Services"
						rules={[
							{
								required: true,
								message: 'Please select whether this location has VET Services',
							},
						]}>
						<Select placeholder="Enable or Disable?">
							<Select.Option value="1">Yes</Select.Option>
							<Select.Option value="0">No</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="noncompats"
						label="Non-Compat Types"
						rules={[
							{
								required: true,
								message: 'Please select at least Non-Compat type',
							},
						]}>
						<Select
							placeholder="select a Non-Compat type"
							mode="multiple"
							allowClear
							options={nonCompatOptions}
						/>
					</Form.Item>

					<Form.Item
						name="hasDogTraining"
						label="Dog Training"
						rules={[
							{
								required: true,
								message: 'Please select whether this location has Dog Training',
							},
						]}>
						<Select placeholder="Enable or Disable?">
							<Select.Option value="1">Yes</Select.Option>
							<Select.Option value="0">No</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item name="alternates" label="Alternative Locations">
						<Select
							placeholder="Aelect Alternate locations"
							mode="multiple"
							allowClear
							options={locationOptions}
						/>
					</Form.Item>

					<Form.Item
						name="vetstoriaMobile"
						label="Vetstoria Mobile URL"
						tooltip="Enter the Vetstoria URL for Mobile."
						rules={[
							{
								validator: validateVetstoriaUrls,
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="vetstoriaWeb"
						label="Vetstoria Web URL"
						tooltip="Enter the Vetstoria URL for Web."
						rules={[
							{
								validator: validateVetstoriaUrls,
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="merchantId"
						label="Merchant Id"
						tooltip="Merchant ID used for payments. ">
						<Input />
					</Form.Item>

					<Form.List name="featureReservationTypeServices">
						{(fields, { add }) => (
							<div
								style={{
									display: 'flex',
									rowGap: 16,
									flexDirection: 'column',
								}}>
								{fields.map((field, index) => (
									<FeatureCardComponent
										form={form}
										field={field}
										key={field.key}
										index={index}
										location={location}
										onRemove={onFeatureServiceRemove}
										reservationTypes={reservationTypes.filter(
											(reservationType) =>
												!selectedReservationTypes?.includes?.(
													reservationType.id,
												),
										)}
										onReservationTypeChange={(reservationTypeId) =>
											setSelectedReservationTypes((state) =>
												Array.from(new Set([...state, reservationTypeId])),
											)
										}
									/>
								))}

								<Button
									type="link"
									onClick={() => add()}
									style={{ width: '100%' }}>
									Add Featured Service
								</Button>
							</div>
						)}
					</Form.List>
				</Form>
			)}
		</div>
	);
};

const FeatureCardComponent = ({
	form,
	field,
	location,
	index,
	reservationTypes,
	onReservationTypeChange,
	onRemove,
}) => {
	const dispatch = useDispatch();
	const [reservationTypeServices, setReservationTypesServices] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedServiceOne, setSelectedServiceOne] = useState(
		location?.locationFeatureServices?.[index]?.featureOneServiceId,
	);
	const [selectedServiceTwo, setSelectedServiceTwo] = useState(
		location?.locationFeatureServices?.[index]?.featureTwoServiceId,
	);

	const onFeatureReservationTypeChange = useCallback(
		async (reservationTypeId) => {
			setLoading(true);

			const reservationId =
				reservationTypeId ??
				location?.locationFeatureServices?.[index]?.reservationTypeId;

			if (reservationTypeId) {
				// manual triggered, clear reservation type values
				const featureReservationTypes = form.getFieldValue(
					'featureReservationTypeServices',
				);
				featureReservationTypes[index] = {
					...featureReservationTypes[index],
					featureOneServiceId: null,
					featureTwoServiceId: null,
				};
				form.setFieldValue(
					'featureReservationTypeServices',
					featureReservationTypes,
				);
			}

			if (location && reservationId) {
				const { allowable_services: services } = await dispatch(
					getReservationTypeServices({
						locationId: location.id,
						reservationTypeId: reservationId,
					}),
				).unwrap();
				setReservationTypesServices(services ?? []);
			}

			onReservationTypeChange(reservationId);
			setLoading(false);
		},
		[location],
	);

	useEffect(() => {
		onFeatureReservationTypeChange();
	}, [onFeatureReservationTypeChange]);

	return (
		<Card
			size="small"
			title={`Featured Service ${index + 1} `}
			extra={
				<Button
					type="link"
					onClick={() => {
						const featureReservationTypes = form.getFieldValue(
							'featureReservationTypeServices',
						);
						form.setFieldValue(
							'featureReservationTypeServices',
							featureReservationTypes.filter((_, l) => l != index),
						);
						onRemove(index);
					}}>
					X
				</Button>
			}>
			<Form.Item
				label="Reservation Type"
				name={[field.name, 'reservationTypeId']}
				rules={[{ required: true, message: 'Select a Reservation Type' }]}>
				<Select
					showSearch
					placeholder="Select a Reservation Type"
					optionFilterProp="label"
					onChange={onFeatureReservationTypeChange}
					filterSort={(optionA, optionB) =>
						(optionA?.label ?? '')
							.toLowerCase()
							.localeCompare((optionB?.label ?? '').toLowerCase())
					}
					value={selectedServiceOne}
					style={{ width: '100%' }}
					options={reservationTypes.map((reservationType) => ({
						label: reservationType.name,
						value: reservationType.id,
					}))}
				/>
			</Form.Item>

			{loading && <Spin spinning={loading} />}

			{!loading && (
				<Form.Item
					label="Featured Service 1"
					name={[field.name, 'featureOneServiceId']}
					rules={[{ required: true, message: 'Select a Feature service' }]}>
					<Select
						disabled={loading}
						showSearch
						placeholder="Select a Service"
						optionFilterProp="label"
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						style={{ width: '100%' }}
						value={selectedServiceTwo}
						options={reservationTypeServices
							?.filter((service) => service.id != selectedServiceTwo)
							.map((service) => ({
								label: service.name,
								value: service.id,
							}))}
						onChange={(value) => setSelectedServiceOne(value)}
					/>
				</Form.Item>
			)}

			{!loading && (
				<Form.Item
					label="Featured Service 2"
					name={[field.name, 'featureTwoServiceId']}
					rules={[{ required: true, message: 'Select a Feature service' }]}>
					<Select
						disabled={loading}
						showSearch
						placeholder="Select Service"
						optionFilterProp="label"
						defaultValue={
							location?.locationFeatureServices?.[index]?.featureTwoServiceId
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						style={{ width: '100%' }}
						options={reservationTypeServices
							?.filter((service) => service.id != selectedServiceOne)
							?.map((service) => ({
								label: service.name,
								value: service.id,
							}))}
						onChange={(value) => setSelectedServiceTwo(value)}
					/>
				</Form.Item>
			)}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.locations,
		noncompats: state.noncompats.noncompats,
	};
};

const mapDispatchToProps = {
	getNonCompats,
	updateLocation,
	getLocationReservationTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);
