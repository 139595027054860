import Network from './Network';

class MinstayService {

	static async getAll() {
		let response = await Network.request({
			method: 'GET',
			url: '/admin/minstays',
		});
		return response.data;
	}

	static async create(payload) {
		let response = await Network.request({
			method: 'POST',
			url: '/admin/minstays',
			data: payload
		});
		return response.data;
	}

	static async update(payload) {
		let response = await Network.request({
			method: 'PUT',
			url: '/admin/minstays',
			data: payload
		});
		return response.data;
	}

	static async delete(id) {
		let response = await Network.request({
			method: 'DELETE',
			url: `/admin/minstays/${id}`,
		});
		return response.data;
	}

}

export default MinstayService;
