import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import {
	AppstoreAddOutlined,
	UnorderedListOutlined,
	CalendarOutlined,
	PictureOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

const LeftMenu = () => {

	const [current, setCurrent] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// set the active menu to the current url
		if (location) {
			const { pathname } = location;
			setCurrent(pathname.replace('/', ''));
		}
	}, [location]);

	const onClick = (e) => {
		setCurrent(e.key);
		navigate(`/${e.key}`);
	};

	const items = [
		{
			label: 'Locations',
			key: 'locations',
			icon: <UnorderedListOutlined />,
		},
		{
			label: 'Non-Compat Types',
			key: 'noncompats',
			icon: <AppstoreAddOutlined />,
		},
		{
			label: 'Minimum Stays',
			key: 'minstays',
			icon: <CalendarOutlined />,
		},
		{
			label: 'Picture Of The Week',
			key: 'pictureweek',
			icon: <PictureOutlined />,
		},
	];

	return (
		<div>
			<div className="logo">
				<Logo />
			</div>
			<Menu onClick={onClick} selectedKeys={[current]} mode="vertical" items={items} />
		</div>
	);
};

export default LeftMenu;
