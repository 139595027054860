import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, DatePicker } from 'antd';
const { Option } = Select;
import { createMinstay } from '../redux/minstay';
import { getLocations } from '../redux/location';
import dayjs from 'dayjs';

const CreateMinstay = ({ locations, locationIds, getLocations, createMinstay, onClose }) => {
	const [form] = Form.useForm();
	const [locationOptions, setLocationOptions] = useState(null);

	useEffect(() => {
		if (!locations) {
			getLocations();
		}
	}, [getLocations]);

	// set up locations
	useEffect(() => {
		if (Array.isArray(locationIds)) {
			const _options = [];
			for (const locId of locationIds) {
				_options.push({
					label: locations[locId].name,
					value: parseInt(locations[locId].id, 10),
				});
			}
			setLocationOptions(_options);
		}
	}, [locations, locationIds]);

	const onMinstaySubmit = async (payload) => {
		const request = {
			...payload,
			start: dayjs(payload.start).format('YYYY-MM-DD'),
			end: dayjs(payload.end).format('YYYY-MM-DD')
		};
		await createMinstay(request);
		form.resetFields();
		onClose();
	};

	const handleChange = (selected) => {
		if (selected && selected[0] == 'all') {
			const _locIds = locationOptions.map(loc => loc.value);
			form.setFieldValue('locations', _locIds);
		}
	};

	return (
		<Form
			form={form}
			id="create-minstay"
			name="create-minstay"
			onFinish={onMinstaySubmit}
			style={{
				maxWidth: 500,
			}}
			scrollToFirstError>
			<Form.Item
				name="label"
				label="Label"
				tooltip="Use a label to quickly identify this date range in the future">
				<Input />
			</Form.Item>

			<Form.Item
				name="start"
				label="Start Date">
				<DatePicker />
			</Form.Item>

			<Form.Item
				name="end"
				label="End Date">
				<DatePicker />
			</Form.Item>

			<Form.Item
				name="nights"
				label="Nights"
				tooltip="Enter the minimum nights for this date range">
				<Select placeholder="select a duration">
					<Option value="1">1 night</Option>
					<Option value="2">2 nights</Option>
					<Option value="3">3 nights</Option>
					<Option value="4">4 nights</Option>
					<Option value="5">5 nights</Option>
					<Option value="6">6 nights</Option>
					<Option value="7">7 nights</Option>
				</Select>
			</Form.Item>

			<Form.Item
				name="locations"
				label="Locations"
				rules={[
					{
						required: true,
						message: 'Please select the applicable locations',
					},
				]}>
				{locationOptions && <Select
					placeholder="select locations"
					mode="multiple"
					onChange={handleChange}
					allowClear>
					<Option value="all">All Locations</Option>
					{locationOptions.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
				</Select>}
			</Form.Item>
		</Form>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.locations,
		...state.minstays
	};
};

const mapDispatchToProps = { getLocations, createMinstay };

export default connect(mapStateToProps, mapDispatchToProps)(CreateMinstay);
