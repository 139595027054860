import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Space } from 'antd';
import { SettingOutlined, LogoutOutlined, ReloadOutlined } from '@ant-design/icons';
import { resetAlternates } from '../redux/location';
import AuthService from '../services/Auth';

const TopMenu = ({ resetAlternates }) => {

	const resetAlts = async () => {
		console.log('resetting')
		await resetAlternates({ miles: 25 });
	};

	const logout = async () => {
		await AuthService.signOut();
	};

	const items = [
		{
			label: 'Reset Alternates',
			key: 'reset',
			onClick: resetAlts,
			icon: <ReloadOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: 'Logout',
			key: 'logout',
			onClick: logout,
			icon: <LogoutOutlined />
		},
	];

	return (
		<Space style={{ float: 'right' }}>
			<Dropdown menu={{ items }}>
				<a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
					<Space>
						<SettingOutlined />
						Manage
					</Space>
				</a>
			</Dropdown>
		</Space>
	);
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { resetAlternates };

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
