import React from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { createNonCompat } from '../redux/noncompat';

const CreateNonCompat = ({ createNonCompat, onClose }) => {
	const [form] = Form.useForm();

	const onSubmit = async (type) => {
		await createNonCompat(type);
		form.resetFields();
		onClose();
	}

	return (
		<div>
			<Form
				form={form}
				id="create-noncompat"
				name="create-noncompat"
				onFinish={onSubmit}
				style={{
					maxWidth: 700,
				}}
				scrollToFirstError>
				<Form.Item
					name="id"
					label="Gingr ID"
					tooltip="Please enter the Gingr ID for the Non-compat type">
					<Input />
				</Form.Item>
				<Form.Item
					name="name"
					label="Name"
					tooltip="Please enter the Name of Non-compat Type">
					<Input />
				</Form.Item>
			</Form>
		</div >
	);
}

const mapStateToProps = () => ({});

const mapDispatchToProps = { createNonCompat };

export default connect(mapStateToProps, mapDispatchToProps)(CreateNonCompat);
