import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, DatePicker } from 'antd';
const { Option } = Select;
import { updateMinstay } from '../redux/minstay';
import { getLocations } from '../redux/location';
import dayjs from 'dayjs';

const EditMinstay = ({ selected, locations, locationIds, getLocations, updateMinstay, onClose }) => {
	const [locationOptions, setLocationOptions] = useState(null);
	const [minstay, setMinstay] = useState(null);

	useEffect(() => {
		if (!locations) {
			getLocations();
		}
	}, [getLocations]);

	// set up locations
	useEffect(() => {
		if (Array.isArray(locationIds)) {
			const _options = [];
			for (const locId of locationIds) {
				_options.push({
					label: locations[locId].name,
					value: parseInt(locations[locId].id, 10),
				});
			}
			setLocationOptions(_options);
		}
	}, [locations, locationIds]);

	// set minstay from redux
	useEffect(() => {
		if (selected) {
			setMinstay(selected);
		} else {
			setMinstay(null);
		}
	}, [selected]);

	const onMinstaySubmit = async (formData) => {
		const payload = {
			id: minstay.id,
			...formData,
			start: dayjs(formData.start).format('YYYY-MM-DD'),
			end: dayjs(formData.end).format('YYYY-MM-DD')
		}
		await updateMinstay(payload);
		onClose();
	};

	return (
		<div>
			{minstay && <Form
				id="edit-minstay"
				name="edit-minstay"
				onFinish={onMinstaySubmit}
				initialValues={{
					label: minstay.label,
					start: dayjs(minstay.start),
					end: dayjs(minstay.end),
					nights: minstay.nights,
					locations: minstay.locations.map(loc => loc.locId),
				}}
				style={{
					maxWidth: 500,
				}}
				scrollToFirstError>
				<Form.Item
					name="label"
					label="Label"
					tooltip="Use a label to quickly identify this date range in the future">
					<Input />
				</Form.Item>

				<Form.Item
					name="start"
					label="Start Date">
					<DatePicker />
				</Form.Item>

				<Form.Item
					name="end"
					label="End Date">
					<DatePicker />
				</Form.Item>

				<Form.Item
					name="nights"
					label="Nights"
					tooltip="Enter the minimum nights for this date range">
					<Select placeholder="select a duration">
						<Option value="1">1 night</Option>
						<Option value="2">2 nights</Option>
						<Option value="3">3 nights</Option>
						<Option value="4">4 nights</Option>
						<Option value="5">5 nights</Option>
						<Option value="6">6 nights</Option>
						<Option value="7">7 nights</Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="locations"
					label="Locations"
					rules={[
						{
							required: true,
							message: 'Please select the applicable locations',
						},
					]}>
					<Select
						placeholder="select locations"
						mode="multiple"
						allowClear
						options={locationOptions}
					/>
				</Form.Item>
			</Form>}
		</div >

	);
};

const mapStateToProps = (state) => {
	return {
		...state.locations,
		...state.minstays,
	};
};

const mapDispatchToProps = { getLocations, updateMinstay };

export default connect(mapStateToProps, mapDispatchToProps)(EditMinstay);
