import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getNonCompats, deleteNonCompat } from '../redux/noncompat'
import { Table, Drawer, Space, Button, Popconfirm } from 'antd';
import {
	DeleteOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons';
import CreateNonCompat from '../components/CreateNonCompat';

const NonCompatTypes = ({ noncompats, getNonCompats, deleteNonCompat }) => {
	const [open, setOpen] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);

	useEffect(() => {
		getNonCompats();
	}, [getNonCompats]);

	useEffect(() => {
		if (noncompats) {
			const _types = noncompats.map(type => {
				return {
					key: type.nonCompatId,
					nonCompatId: type.nonCompatId,
					name: type.name
				};
			});
			setTableData(_types);
		}
		const _columns = [
			{
				title: 'Gingr ID',
				dataIndex: 'nonCompatId',
			},
			{
				title: 'Name',
				dataIndex: 'name',
			},
			{
				title: 'Action',
				render: (text, row) => {
					return (
						<Popconfirm
							title="Please Confirm"
							description="Are you sure to delete this?"
							onConfirm={() => onDelete(row.nonCompatId)}
							okText="Yes"
							cancelText="No">
							<Button>
								<DeleteOutlined />
							</Button>
						</Popconfirm>
					)
				}
			}
		];
		setTableColumns(_columns);
	}, [noncompats]);

	const onClose = () => {
		setOpen(false);
	}

	const onDelete = async (nonCompatId) => {
		await deleteNonCompat(nonCompatId);
	}

	return (
		<div>
			<Space style={{ float: 'right', marginBottom: 16 }}>
				<Button type="primary" onClick={() => setOpen(true)} style={{ background: '#008E87' }}>
					<PlusCircleOutlined /> New Type
				</Button>
			</Space>
			{tableData && <Table
				dataSource={tableData}
				columns={tableColumns}
				size={'middle'}
				pagination={false}
			/>}
			<Drawer
				title={'Create Non-compat Type'}
				placement="right"
				size={'large'}
				width={700}
				onClose={onClose}
				open={open}
				extra={
					<Space>
						<Button onClick={onClose}>Cancel</Button>
						<Button type="primary" form="create-noncompat" htmlType='submit'>
							Create
						</Button>
					</Space>
				}>
				<CreateNonCompat onClose={onClose} />
			</Drawer>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.noncompats
	};
};

const mapDispatchToProps = { getNonCompats, deleteNonCompat };

export default connect(mapStateToProps, mapDispatchToProps)(NonCompatTypes);
